import { Badge, Button } from "@getwellen/valesco";
import { ContentLoader } from "components/content-loader/ContentLoader";
import PageHeader from "components/page-header/PageHeader";
import {
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableHeaderCol,
  TableRow
} from "components/table";
import { TablePagination } from "components/table/TablePagination";
import TableSearchForm from "components/table/TableSearchForm";
import { formatDistance } from "date-fns";
import { memo } from "react";
import { Link } from "react-router-dom";

import useTransitionsListPage from "./useTransitionsListPage";

const TransitionsListPage = () => {
  const {
    page,
    hasMore,
    transitions,
    handleNext,
    handlePrev,
    isLoading,
    handleSearch,
    totalCount
  } = useTransitionsListPage();

  if (isLoading) return <ContentLoader />;

  return (
    <>
      <PageHeader className="mb-8" title={`Transitions (${totalCount})`} />
      <TableSearchForm
        className="mb-8"
        onSearch={handleSearch}
        placeholder="Find a video by its slug"
      />
      <Table>
        <TableHead>
          <TableHeaderCol>Slug</TableHeaderCol>
          <TableHeaderCol>Status</TableHeaderCol>
          <TableHeaderCol>Last Updated</TableHeaderCol>
          <TableHeaderCol>
            <span className="sr-only">actions</span>
          </TableHeaderCol>
        </TableHead>
        <TableBody>
          {transitions.map((transition, idx) => (
            <TableRow key={idx}>
              <TableCol>{transition.slug}</TableCol>
              <TableCol>
                {transition.publishedAt ? (
                  <Badge variant="bold">Published</Badge>
                ) : (
                  <Badge variant="subtle">Unpublished</Badge>
                )}
              </TableCol>
              <TableCol>
                <>
                  Last updated{" "}
                  {formatDistance(new Date(transition.updatedAt), new Date(), {
                    addSuffix: true
                  })}
                </>
              </TableCol>
              <TableCol>
                <Link
                  className="text-cello-500 underline hover:text-cello-900"
                  to={`/transitions/${transition.slug}`}
                >
                  <Button action="secondary" variant="subtle">
                    View
                    <span className="sr-only">, {transition.slug}</span>
                  </Button>
                </Link>
              </TableCol>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        handleNext={handleNext}
        handlePrevious={handlePrev}
        page={page}
        pageSize={10}
        showNext={hasMore}
        showPrev={page > 1}
        total={totalCount}
      />
    </>
  );
};

export default memo(TransitionsListPage);
