import { useEnv } from "contexts";
import {
  Transition,
  TransitionEntity,
  useTransitionsQuery
} from "graphql/strapi-cms";
import { useParams } from "react-router-dom";

export default function useTransitionsViewPage() {
  const { slug, tab = "videos" } = useParams();
  const { CMS_API_URL } = useEnv();

  const { data, loading: isLoading } = useTransitionsQuery({
    variables: {
      slug: slug
    },
    skip: !slug
  });

  const transitionData = data?.transitions?.data?.[0] as TransitionEntity;
  const transition = transitionData?.attributes as Transition;

  return {
    transition,
    transitionId: transitionData?.id,
    isLoading: isLoading,
    currentTab: tab,
    cmsUrl: CMS_API_URL
  };
}
