import { Button } from "@getwellen/valesco";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";

const TablePagination: React.FC<{
  total: number;
  pageSize: number;
  page: number;
  showNext: boolean;
  showPrev: boolean;
  handlePrevious?(): void;
  handleNext?(): void;
}> = ({
  total,
  pageSize,
  page,
  showNext,
  showPrev,
  handleNext,
  handlePrevious
}) => {
  return (
    <nav
      aria-label="Pagination"
      className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-cello-700">
          Showing{" "}
          <span className="font-bold">
            {total > 0 ? pageSize * (page - 1) + 1 : 0}
          </span>{" "}
          to{" "}
          <span className="font-bold">
            {pageSize * page > total ? total : pageSize * page}
          </span>{" "}
          of <span className="font-bold">{total}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {showPrev && (
          <Button
            action="secondary"
            className="ml-3 underline"
            onClick={handlePrevious}
            variant="minimal"
          >
            <ChevronLeftIcon className="h-5 w-6 mr-2" />
            Previous
          </Button>
        )}
        {showNext && (
          <Button
            action="secondary"
            className="ml-3 underline"
            onClick={handleNext}
            variant="minimal"
          >
            Next
            <ChevronRightIcon className="h-5 w-6 ml-2" />
          </Button>
        )}
      </div>
    </nav>
  );
};

export { TablePagination };
