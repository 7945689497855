import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";

const PrivateRoute: React.FC<{
  // eslint-disable-next-line
  component: any;
}> = ({ component, ...args }) => {
  const PrivateComponent = withAuthenticationRequired(component);

  return <PrivateComponent {...args} />;
};

export { PrivateRoute };
