import { CalendarIcon, ClockIcon } from "@heroicons/react/24/outline";
import PageHeader from "components/page-header/PageHeader";
import { Tabs } from "components/tabs/Tabs";
import { formatDistance } from "date-fns";
import { memo } from "react";
import { parametersToJson } from "util/rendering";
import { secondsToTimeStr } from "util/time";

import VideosPlaylistsTab from "./tabs/VideosPlaylistsTab";
import useVideosViewPage from "./useVideosViewPage";

const VideosViewPage = () => {
  const { video, isLoading, currentTab } = useVideosViewPage();

  if (isLoading) return null;

  const {
    render: { config }
  } = video;

  const renderCurrentTab = () => {
    switch (currentTab) {
      case "playlists":
        return <VideosPlaylistsTab />;
      default:
        return null;
    }
  };

  return (
    <div>
      <PageHeader
        breadcrumbs={[
          {
            href: "/videos",
            label: "Videos"
          },
          {
            href: `/videos/${video.id}`,
            label: String(config.id)
          }
        ]}
        metaItems={[
          {
            icon: ClockIcon,
            label: secondsToTimeStr(video.duration)
          },
          {
            icon: CalendarIcon,
            label: (
              <>
                Last updated{" "}
                {formatDistance(new Date(video.updatedAt), new Date(), {
                  addSuffix: true
                })}
              </>
            )
          }
        ]}
        title={config.slug}
      />
      <p className="mt-8 mb-4">
        <span className="text-sm mb-2 block">Configuration</span>
      </p>
      <pre className="bg-cello-500 block w-full text-white p-6 rounded-lg">
        {video.render.config.parameters &&
          parametersToJson(video.render.config)}
      </pre>
      <Tabs
        tabs={[
          {
            name: "Playlists",
            href: `/videos/${video.id}/playlists`,
            current: currentTab === "playlists"
          }
        ]}
      />
      <div className="py-8 mb-8 border-b">{renderCurrentTab()}</div>
    </div>
  );
};

export default memo(VideosViewPage);
