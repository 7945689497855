import { type TimelineItem } from "@getwellen/valesco";
import type {
  StitchVideo,
  StitchVideoWithRenderAndConfig,
  VideoConfigParameters,
  VideoSegment,
} from "@getwellen/video-streaming";

import {
  ComponentWorkoutExercise,
  ComponentWorkoutTransition,
  Workout,
} from "../graphql/strapi-cms";

const buildStream = (workout: Workout, endDuration: 10) => {
  const routine = workout?.routine || [];

  if (routine.length === 0) {
    return [];
  }

  const segments: VideoSegment[] = routine
    .filter(
      item =>
        item?.__typename === "ComponentWorkoutExercise" ||
        item?.__typename === "ComponentWorkoutTransition"
    )
    .map(item => {
      if (item?.__typename === "ComponentWorkoutTransition") {
        const transition = item as ComponentWorkoutTransition;

        return {
          videoType: "Transition",
          slug: String(transition.transition?.data?.attributes?.slug),
          attributes: {
            duration: transition.duration,
          },
        };
      } else if (item?.__typename === "ComponentWorkoutExercise") {
        const exercise = item as ComponentWorkoutExercise;
        const sets = Number(exercise.sets || 1);
        const baseAttributes = {
          sets: Number(exercise.sets || 1),
          ...(sets > 1 && exercise.restDuration > 0 ? { restDuration: exercise.restDuration } : {}),
        };

        let attributes: VideoConfigParameters = { ...baseAttributes };

        if (Number(exercise.holdDuration) > 0) {
          attributes = {
            ...attributes,
            holdDuration: Number(exercise.holdDuration || 0),
          };
        } else {
          attributes = {
            ...attributes,
            reps: Number(exercise.reps),
          };
        }

        return {
          videoType: "Exercise",
          slug: String(exercise.exercise?.data?.attributes?.slug),
          attributes: attributes,
        };
      } else {
        return undefined;
      }
    })
    .filter(item => !!item)
    .map(item => item as VideoSegment);

  return segments.concat({
    videoType: "Transition",
    slug: "workout-end",
    attributes: {
      duration: endDuration,
    },
  });
};

const buildStreamTimeline = (videos: StitchVideoWithRenderAndConfig[]): TimelineItem[] =>
  videos
    .filter(v => v.video.render.videoType === "Exercise")
    .map(v => ({
      startsAt: getVideoStartTime(v, videos),
      endsAt: getVideoEndTime(v, videos),
      slug: v.video.render.config.slug,
      component: { type: "Exercise" },
    }));

const getVideoStartTime = (
  targetVideo: StitchVideo,
  allVideos: StitchVideoWithRenderAndConfig[]
) => {
  let startTime = 0.0;

  for (const stitchVideo of allVideos) {
    if (targetVideo.id === stitchVideo.id) {
      break;
    }
    startTime += stitchVideo.video.duration;
  }

  return startTime;
};

const getVideoEndTime = (
  targetVideo: StitchVideoWithRenderAndConfig,
  allVideos: StitchVideoWithRenderAndConfig[]
) => {
  let endTime = 0.0;

  for (const stitchVideo of allVideos) {
    if (targetVideo.id === stitchVideo.id) {
      break;
    }
    endTime += stitchVideo.video.duration;
  }

  endTime += targetVideo.video.duration;

  return endTime;
};

export { buildStream, buildStreamTimeline, getVideoEndTime, getVideoStartTime };
