import TransitionsListPage from "pages/transitions/list/TransitionsListPage";
import TransitionsPreviewPage from "pages/transitions/preview/TransitionsPreviewPage";
import TransitionsViewPage from "pages/transitions/view/TransitionsViewPage";
import React from "react";

import { type RouteItem } from ".";

const routes: RouteItem[] = [
  {
    path: "/transitions",
    element: (
      <React.Suspense>
        <TransitionsListPage />
      </React.Suspense>
    )
  },
  {
    path: "/transitions/:slug/preview",
    element: (
      <React.Suspense>
        <TransitionsPreviewPage />
      </React.Suspense>
    )
  },
  {
    path: "/transitions/:slug/:tab",
    element: (
      <React.Suspense>
        <TransitionsViewPage />
      </React.Suspense>
    )
  },
  {
    path: "/transitions/:slug",
    element: (
      <React.Suspense>
        <TransitionsViewPage />
      </React.Suspense>
    )
  }
];

export { routes };
