import { Button, notify } from "@getwellen/valesco";
import { Badge } from "components/badge/Badge";
import { ContentLoader } from "components/content-loader/ContentLoader";
import {
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableHeaderCol,
  TableRow
} from "components/table";
import { useModal } from "contexts/ModalContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getVideoConfigLabel, parametersToJson } from "util/rendering";

import useTransitionsConfigsTab from "./useTransitionsConfigsTab";

export default function TransitionsConfigsTab() {
  const { slug } = useParams();
  const { findLatestRender, configs, isLoading, onCreateRender } =
    useTransitionsConfigsTab();
  const { setModal } = useModal();
  const navigate = useNavigate();

  if (isLoading) return <ContentLoader />;

  return (
    <Table>
      <TableHead>
        <TableHeaderCol>Latest Render</TableHeaderCol>
        <TableHeaderCol>Video Config</TableHeaderCol>
        <TableHeaderCol>Parameters</TableHeaderCol>
        <TableHeaderCol></TableHeaderCol>
      </TableHead>
      <TableBody>
        {configs.map((config, idx) => {
          const latestRender = findLatestRender(config);

          return (
            <TableRow key={idx}>
              <TableCol>
                {latestRender ? (
                  <Link to={`/renders/${latestRender.id}`}>
                    {latestRender.id}
                  </Link>
                ) : (
                  <Badge className="bg-gray-300">None</Badge>
                )}
              </TableCol>
              <TableCol>
                <Badge className="bg-cello-500 text-white">
                  {getVideoConfigLabel(config)}
                </Badge>
              </TableCol>
              <TableCol>
                <pre>{parametersToJson(config)}</pre>
              </TableCol>
              <TableCol className="flex flex-row gap-x-4">
                <Link to={`/transitions/${slug}/preview?configId=${config.id}`}>
                  <Button action="secondary" variant="subtle">
                    Preview
                  </Button>
                </Link>
                <Button
                  onClick={() =>
                    setModal(
                      <>
                        <h1 className="text-xl mb-2">
                          Are you sure you want to create a new render?
                        </h1>
                        <pre className="mb-4">
                          {JSON.stringify(config.parameters, null, 2)}
                        </pre>
                        <div className="flex flex-row justify-between">
                          <Button
                            action="secondary"
                            onClick={() => setModal(null)}
                            variant="subtle"
                          >
                            No, cancel
                          </Button>
                          <Button
                            onClick={() =>
                              onCreateRender(config)
                                .then((render) => {
                                  setModal(null);
                                  navigate(`/renders/${render.id}`);
                                })
                                .catch((err) => {
                                  notify.error(err.toString());
                                })
                            }
                          >
                            Yes, continue
                          </Button>
                        </div>
                      </>
                    )
                  }
                >
                  Render Video
                </Button>
              </TableCol>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
