import { secondsToFrames } from "@getwellen/remotion";
import type {
  VideoConfig,
  VideoConfigParameters
} from "@getwellen/video-streaming";
import {
  PublicationState,
  Transition,
  TransitionEntity,
  useTransitionsQuery
} from "graphql/strapi-cms";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { trpc } from "util/trpc";

export default function useTransitionsViewPage() {
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedConfig, setSelectedConfig] = useState<VideoConfig>();

  const { data, loading: isLoading } = useTransitionsQuery({
    variables: {
      publicationState: PublicationState.Preview,
      slug: String(slug)
    },
    skip: !slug
  });

  const { data: configData, isLoading: isConfigLoading } =
    trpc.videoStreaming.videoConfigs.getByVideoTypeAndSlug.useQuery({
      videoType: "Transition",
      slug: String(slug)
    });

  const transitionData = (data?.transitions?.data?.[0] ||
    {}) as TransitionEntity;
  const transition = transitionData.attributes as Transition;

  useEffect(() => {
    if (configData && !selectedConfig) {
      setSelectedConfig(
        searchParams.get("configId")
          ? configData.find(
              (config) => config.id === searchParams.get("configId")
            ) || configData[0]
          : configData[0]
      );
    }
  }, [configData]);

  const parameters = (selectedConfig?.parameters ||
    {}) as VideoConfigParameters;

  return {
    parameters,
    duration: parameters.duration || 1,
    durationInFrames: secondsToFrames(parameters.duration || 1),
    transition,
    isLoading: isLoading || isConfigLoading,
    configs: configData || [],
    selectedConfig: selectedConfig,
    onConfigChange: (e: ChangeEvent) => {
      const target = e.target as HTMLSelectElement;
      setSelectedConfig(
        configData?.find((config) => config.id === target.value)
      );
    }
  };
}
