import React from "react";
import { twMerge } from "tailwind-merge";

const Badge: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className = "", children }) => (
  <span
    className={twMerge(
      "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium text-cello-800",
      className
    )}
  >
    {children}
  </span>
);

export { Badge };
