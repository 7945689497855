import type { PlaylistWithVideoRenderAndConfig } from "@getwellen/video-streaming";
import { useParams } from "react-router-dom";
import { trpc } from "util/trpc";

export default function useWatchPlaylistPage() {
  const { playlistId } = useParams();

  const { data, isLoading } = trpc.videoStreaming.playlists.getById.useQuery(
    {
      id: String(playlistId)
    },
    { enabled: !!playlistId }
  );

  return {
    playlist: data as PlaylistWithVideoRenderAndConfig,
    isLoading
  };
}
