import type { Render, VideoConfig } from "@getwellen/video-streaming";
import { useParams } from "react-router-dom";
import { trpc } from "util/trpc";

export default function useTransitionsConfigsTab() {
  const { slug } = useParams();

  const { data, isLoading } =
    trpc.videoStreaming.videoConfigs.getByVideoTypeAndSlug.useQuery({
      videoType: "Transition",
      slug: String(slug)
    });

  const createRender = trpc.videoStreaming.renders.createAndQueue.useMutation();

  const onCreateRender = async (config: VideoConfig): Promise<Render> => {
    const render = await createRender.mutateAsync({
      config,
      videoType: "Transition",
      machineConfigId: "default"
    });

    if (!render) {
      throw new Error("Failed to create render");
    }

    return render;
  };

  const findLatestRender = (config: VideoConfig) => {
    return data?.find((c) => c.id === config.id)?.renders?.[0];
  };

  return {
    onCreateRender,
    findLatestRender,
    configs: data || [],
    isLoading
  };
}
