import { Button } from "@getwellen/valesco";
import { ContentLoader } from "components/content-loader/ContentLoader";
import {
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableHeaderCol,
  TableRow
} from "components/table";
import { Link, useParams } from "react-router-dom";
import { bytesToMbps } from "util/video";

import useVideosPlaylistsTab from "./useVideosPlaylistsTab";

export default function VideosPlaylistsTab() {
  const { id } = useParams();
  const { playlists, isLoading } = useVideosPlaylistsTab();

  if (isLoading) return <ContentLoader />;

  return (
    <div>
      <Table>
        <TableHead>
          <TableHeaderCol>Resolution</TableHeaderCol>
          <TableHeaderCol>Bandwidth</TableHeaderCol>
          <TableHeaderCol>HLS Manifest</TableHeaderCol>
          <TableHeaderCol></TableHeaderCol>
        </TableHead>
        <TableBody>
          {playlists.map((playlist) => (
            <TableRow key={playlist.id}>
              <TableCol>{playlist.resolution}</TableCol>
              <TableCol>{bytesToMbps(playlist.bandwidth)} mbps</TableCol>
              <TableCol>
                <Link className="underline" target="_blank" to={playlist.url}>
                  index.m3u8
                </Link>
              </TableCol>
              <TableCol>
                <Link to={`/videos/${id}/playlists/${playlist.id}`}>
                  <Button action="secondary" variant="subtle">
                    Watch
                  </Button>
                </Link>
              </TableCol>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
