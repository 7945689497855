import { VideoConfig, VideoConfigParameters } from "@getwellen/video-streaming";

const getRenderStatusColor = (status: string): string => {
  switch (status.toLowerCase()) {
    case "queued":
      return "bg-gray-300";
    case "starting":
      return "bg-gray-300";
    case "rendering":
      return "bg-gray-300";
    case "processing":
      return "bg-gray-300";
    case "complete":
      return "bg-green text-white";
    case "failed":
      return "bg-red text-white";
    default:
      return "bg-cello-500 text-white";
  }
};

const isVideoRenderStatusInProgress = (status: string): boolean =>
  ["Starting", "Rendering", "Processin"].includes(status);

const getVideoConfigLabel = (config: VideoConfig) => {
  const { sets, reps, holdDuration, restDuration, duration } =
    config.parameters as VideoConfigParameters;
  const rest = Number(restDuration) > 0 ? `${restDuration}RST` : undefined;

  if (Number(duration) > 0) {
    return `${duration}SEC`;
  }

  if (Number(holdDuration) > 0) {
    return `${sets}S - ${holdDuration}HLD${rest ? ` - ${rest}` : ""}`;
  }

  return `${sets}S - ${reps}R ${rest ? ` - ${rest}` : ""}`;
};

const getReadableVideoConfigLabel = (config: VideoConfig) => {
  const { sets, reps, holdDuration, restDuration, duration } =
    config.parameters as VideoConfigParameters;

  const rest =
    Number(restDuration) > 0 ? `${restDuration} seconds rest` : undefined;

  if (Number(duration) > 0) {
    return `${duration} seconds`;
  }

  if (Number(holdDuration) > 0) {
    return `${sets} sets with a ${holdDuration} second hold ${
      rest ? ` -- ${rest}` : ""
    }`;
  }

  return `${sets} sets with ${reps} reps ${rest ? ` -- ${rest}` : ""}`;
};

const parametersToJson = (config: VideoConfig) => {
  const parameters = config.parameters || {};

  const filteredParams = Object.fromEntries(
    Object.entries(parameters).filter(([_key, value]) => value !== 0)
  );

  return JSON.stringify(filteredParams, null, 2);
};

export {
  getReadableVideoConfigLabel,
  getRenderStatusColor,
  getVideoConfigLabel,
  isVideoRenderStatusInProgress,
  parametersToJson
};
