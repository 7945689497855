import { Spinner } from "@getwellen/valesco";
import React from "react";

const ContentLoader: React.FC = () => (
  <div className="flex justify-center items-center w-full h-screen -mt-12">
    <Spinner className="fill-geebung-500" />
  </div>
);

export { ContentLoader };
