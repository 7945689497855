import { Auth0Provider } from "@auth0/auth0-react";
import { ToastNotification } from "@getwellen/valesco";
import { BrowserRouter } from "react-router-dom";

import {
  GraphqlProvider,
  TitleProvider,
  TRPCProvider,
  useEnv
} from "./contexts";
import { FeatureFlagProvider } from "./contexts/FeatureFlagContext";
import { ModalProvider } from "./contexts/ModalContext";
import { Router } from "./router";

function App() {
  const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } = useEnv();

  return (
    <Auth0Provider
      authorizationParams={{
        audience: AUTH0_AUDIENCE,
        redirect_uri: window.location.origin + "/authenticate"
      }}
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
    >
      <GraphqlProvider>
        <TRPCProvider>
          <FeatureFlagProvider>
            <BrowserRouter>
              <TitleProvider>
                <ToastNotification />
                <ModalProvider>
                  <Router />
                </ModalProvider>
              </TitleProvider>
            </BrowserRouter>
          </FeatureFlagProvider>
        </TRPCProvider>
      </GraphqlProvider>
    </Auth0Provider>
  );
}

export default App;
