import React from "react";

import { RouteItem } from ".";

const PatientsListPage = React.lazy(
  () => import("pages/patients/list/PatientsListPage")
);

const PatientsViewPage = React.lazy(
  () => import("pages/patients/view/ViewPatientPage")
);

const routes: RouteItem[] = [
  {
    path: "/patients",
    element: (
      <React.Suspense fallback={<></>}>
        <PatientsListPage />
      </React.Suspense>
    )
  },
  {
    path: "/patients/:id/:tab",
    element: (
      <React.Suspense fallback={<></>}>
        <PatientsViewPage />
      </React.Suspense>
    )
  },
  {
    path: "/patients/:id",
    element: (
      <React.Suspense fallback={<></>}>
        <PatientsViewPage />
      </React.Suspense>
    )
  }
];

export { routes };
