import { useTitle } from "contexts";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { trpc } from "util/trpc";

export default function useVideosListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setTitle } = useTitle();
  const limit = 20;
  const searchTerm = searchParams.get("search") || "";
  const page = Number(searchParams.get("page")) || 1;

  const { data, isLoading } = trpc.videoStreaming.videos.getAll.useQuery({
    limit,
    page,
    search: searchTerm
  });

  useEffect(() => {
    setTitle("Videos");
  }, []);

  const handleNext = useCallback(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: String(page + 1)
    });
  }, [page]);

  const handlePrev = useCallback(() => {
    if (page !== 1)
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: String(page - 1)
      });
  }, [page]);

  const handleSearch = useCallback((term: string) => {
    setSearchParams({ search: term });
  }, []);

  return {
    handleNext,
    handleSearch,
    handlePrev,
    hasMore: (data?.data || []).length >= 20,
    isLoading,
    page,
    totalCount: data?.pagination?.total ?? 0,
    videos: data?.data || []
  };
}
