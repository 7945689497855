import React from "react";

import { RouteItem } from ".";

const ExerciseListPage = React.lazy(
  () => import("pages/exercises/list/ExerciseListPage")
);

const ExerciseEditPage = React.lazy(
  () => import("pages/exercises/view/ExerciseViewPage")
);

const ExercisePreviewPage = React.lazy(
  () => import("pages/exercises/preview/ExercisePreviewPage")
);

const ExerciseSwapRecommenderPage = React.lazy(
  () => import("pages/exercises/swap-recommender/ExerciseSwapRecommenderPage")
);

const routes: RouteItem[] = [
  {
    path: "/exercises",
    element: (
      <React.Suspense fallback={<></>}>
        <ExerciseListPage />
      </React.Suspense>
    )
  },
  {
    path: "/exercises/swap-recommender",
    element: (
      <React.Suspense fallback={<></>}>
        <ExerciseSwapRecommenderPage />
      </React.Suspense>
    )
  },
  {
    path: "/exercises/:slug/preview",
    element: (
      <React.Suspense fallback={<></>}>
        <ExercisePreviewPage />
      </React.Suspense>
    )
  },
  {
    path: "/exercises/:slug/:tab",
    element: (
      <React.Suspense fallback={<></>}>
        <ExerciseEditPage />
      </React.Suspense>
    )
  },
  {
    path: "/exercises/:slug",
    element: (
      <React.Suspense fallback={<></>}>
        <ExerciseEditPage />
      </React.Suspense>
    )
  }
];

export { routes };
