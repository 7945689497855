import VideosListPage from "pages/videos/list/VideosListPage";
import WatchPlaylistPage from "pages/videos/playlists/watch/WatchPlaylistPage";
import VideosViewPage from "pages/videos/view/VideosViewPage";
import React from "react";

import { type RouteItem } from ".";

const routes: RouteItem[] = [
  {
    path: "/videos",
    element: (
      <React.Suspense>
        <VideosListPage />
      </React.Suspense>
    )
  },
  {
    path: "/videos/:id",
    element: (
      <React.Suspense>
        <VideosViewPage />
      </React.Suspense>
    )
  },
  {
    path: "/videos/:id/playlists/:playlistId",
    element: (
      <React.Suspense>
        <WatchPlaylistPage />
      </React.Suspense>
    )
  }
];

export { routes };
