import React from "react";

import { RouteItem } from ".";

const OrdersListPage = React.lazy(
  () => import("pages/orders/list/OrdersListPage")
);

const OrdersViewPage = React.lazy(
  () => import("pages/orders/view/ViewOrderPage")
);

export const routes: RouteItem[] = [
  {
    path: "/orders",
    element: (
      <React.Suspense fallback={<></>}>
        <OrdersListPage />
      </React.Suspense>
    )
  },
  {
    path: "/orders/:id",
    element: (
      <React.Suspense fallback={<></>}>
        <OrdersViewPage />
      </React.Suspense>
    )
  },
  {
    path: "/orders/:id/:tab",
    element: (
      <React.Suspense fallback={<></>}>
        <OrdersViewPage />
      </React.Suspense>
    )
  }
];
