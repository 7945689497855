import { Button } from "@getwellen/valesco";
import { Badge } from "components/badge/Badge";
import { ContentLoader } from "components/content-loader/ContentLoader";
import {
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableHeaderCol,
  TableRow
} from "components/table";
import { formatDistance } from "date-fns";
import { Link } from "react-router-dom";
import { getVideoConfigLabel } from "util/rendering";

import useTransitionsVideosTab from "./useTransitionsVideosTab";

const TransitionsVideosTab = () => {
  const { videos, isLoading } = useTransitionsVideosTab();

  if (isLoading) return <ContentLoader />;

  return (
    <Table>
      <TableHead>
        <TableHeaderCol>Status</TableHeaderCol>
        <TableHeaderCol>Video Config</TableHeaderCol>
        <TableHeaderCol>Created At</TableHeaderCol>
        <TableHeaderCol></TableHeaderCol>
      </TableHead>
      <TableBody>
        {videos.map((video, idx) => (
          <TableRow key={idx}>
            <TableCol>
              {video.isActive ? (
                <Badge className="bg-green text-white">Active</Badge>
              ) : (
                <Badge className="bg-gray-200 text-cello-500">Archived</Badge>
              )}
            </TableCol>
            <TableCol>
              <Badge className="bg-cello-500 text-white">
                {getVideoConfigLabel(video.render.config)}
              </Badge>
            </TableCol>
            <TableCol>
              <>
                Created{" "}
                {formatDistance(new Date(video.createdAt), new Date(), {
                  addSuffix: true
                })}
              </>
            </TableCol>
            <TableCol>
              <Link
                className="text-cello-500 underline hover:text-cello-900"
                to={`/videos/${video.id}`}
              >
                <Button action="secondary" variant="subtle">
                  View
                  <span className="sr-only">, {video.render.config.slug}</span>
                </Button>
              </Link>
            </TableCol>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export { TransitionsVideosTab };
