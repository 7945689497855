type TableHead = {
  children: React.ReactNode;
};

export default function TableHead({ children }: TableHead) {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
}
