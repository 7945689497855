import { TransitionComposition } from "@getwellen/remotion";
import { Player } from "@remotion/player";
import { ContentLoader } from "components/content-loader/ContentLoader";
import PageHeader from "components/page-header/PageHeader";
import { memo } from "react";
import { getVideoConfigLabel } from "util/rendering";

import useTransitionsPreviewPage from "./useTransitionsPreviewPage";

const TransitionsPreviewPage = () => {
  const {
    duration,
    durationInFrames,
    transition,
    isLoading,
    configs,
    selectedConfig,
    onConfigChange
  } = useTransitionsPreviewPage();

  if (isLoading) return <ContentLoader />;

  return (
    <>
      <PageHeader
        breadcrumbs={[
          { label: "Transitions", href: "/transitions" },
          {
            label: transition.slug || "",
            href: `/transitions/${transition.slug}`
          }
        ]}
        title={`Previewing ${transition.name}`}
      />
      <dl className="my-4">
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt className="text-sm font-medium text-cello-500 truncate">
            Video config:
          </dt>
          <dd className="mt-1 text-3xl font-semibold text-cello-900">
            <select
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              id="location"
              name="location"
              onChange={onConfigChange}
              value={selectedConfig?.id}
            >
              {configs.map((config, idx) => (
                <option key={idx} value={config.id}>
                  {getVideoConfigLabel(config)}
                </option>
              ))}
            </select>
          </dd>
        </div>
      </dl>
      <div>
        <Player
          component={TransitionComposition}
          compositionHeight={1080}
          compositionWidth={1920}
          controls={true}
          durationInFrames={durationInFrames}
          fps={23.98}
          inputProps={{
            transition: transition,
            duration
          }}
          numberOfSharedAudioTags={0}
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default memo(TransitionsPreviewPage);
