import { twMerge } from "tailwind-merge";

type TableCol = {
  className?: string;
  children: React.ReactNode;
};

export default function TableCol({ className = "", children }: TableCol) {
  return (
    <td
      className={twMerge(
        "whitespace-nowrap px-3 py-4 text-sm text-cello-500",
        className
      )}
    >
      {children}
    </td>
  );
}
