import { useTitle } from "contexts";
import {
  PublicationState,
  Transition,
  useTransitionsQuery
} from "graphql/strapi-cms";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function useTransitionsListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setTitle } = useTitle();
  const limit = 10;
  const searchTerm = searchParams.get("search") || "";
  const page = Number(searchParams.get("page")) || 1;

  const { data, loading: isLoading } = useTransitionsQuery({
    variables: {
      publicationState: PublicationState.Preview,
      sort: ["updatedAt:desc"],
      search: searchTerm,
      pagination: {
        page,
        pageSize: limit
      }
    }
  });

  useEffect(() => {
    setTitle("Transitions");
  }, []);

  const handleNext = useCallback(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: String(page + 1)
    });
  }, [page]);

  const handlePrev = useCallback(() => {
    if (page !== 1)
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: String(page - 1)
      });
  }, [page]);

  const handleSearch = useCallback((term: string) => {
    setSearchParams({ search: term });
  }, []);

  const transitions = (data?.transitions?.data || []).map(
    (t) => t.attributes as Transition
  );
  const totalCount = data?.transitions?.meta.pagination.total || 0;
  const totalPages: number =
    data?.transitions?.meta?.pagination?.pageCount || 1;
  const hasMore: boolean = Number(page) < totalPages;

  return {
    handleNext,
    handleSearch,
    handlePrev,
    hasMore,
    isLoading,
    page,
    transitions,
    totalCount
  };
}
