import { type VideoWithRenderAndConfig } from "@getwellen/video-streaming";
import { useParams } from "react-router-dom";
import { trpc } from "util/trpc";

export default function useVideosViewPage() {
  const { id } = useParams();

  const { data, isLoading } = trpc.videoStreaming.videos.getById.useQuery(
    {
      id: String(id)
    },
    { enabled: !!id }
  );

  return {
    video: data as VideoWithRenderAndConfig,
    isLoading,
    currentTab: "playlists"
  };
}
