import { useParams } from "react-router-dom";
import { trpc } from "util/trpc";

export default function useTransitionsVideosTab() {
  const { slug } = useParams();

  const { data: videos, isLoading } =
    trpc.videoStreaming.videos.getByTypeAndSlug.useQuery(
      {
        type: "Transition",
        slug: String(slug)
      },
      { enabled: !!slug }
    );

  return {
    videos: videos ?? [],
    isLoading
  };
}
