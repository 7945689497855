import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from "react";

const TitleContext = createContext<{
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
}>({
  title: "",
  setTitle: () => null
});

const useTitle = () => useContext(TitleContext);

const TitleProvider: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [title, setTitle] = useState<string>("");

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  );
};

export { TitleProvider, useTitle };
