import { useAuth0 } from "@auth0/auth0-react";
import Container from "components/containers/Container";
import DesktopNavSidebar from "components/nav/desktop-nav/DesktopNavSidebar";
import MobileDrawer from "components/nav/mobile-nav/MobileDrawer";
import MobileNavBar from "components/nav/mobile-nav/MobileNavBar";
import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { NavItem, navItems } from "../components/nav";
import { useTitle } from "../contexts";

const MainLayout: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { title } = useTitle();
  const { logout } = useAuth0();

  const handleLogout = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  }, []);

  useEffect(() => {
    document.title = "Wellen Admin" + (title ? ` - ${title}` : "");
  }, [title]);

  const mappedNavItems: NavItem[] = navItems.map((item) => ({
    ...item,
    current: window.location.pathname.startsWith(item.href)
  }));

  return (
    <div>
      <MobileDrawer
        items={mappedNavItems}
        onLogoutClick={handleLogout}
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
      />
      <DesktopNavSidebar items={mappedNavItems} onLogoutClick={handleLogout} />
      <MobileNavBar setSidebarOpen={setSidebarOpen} />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <Container>{children || <Outlet />}</Container>
        </div>
      </main>
    </div>
  );
};

export { MainLayout };
