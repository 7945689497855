import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { MainLayout } from "layouts";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType
} from "react-router-dom";
import { privateRoutes, publicRoutes } from "router/routes";

import { PrivateRoute } from "./PrivateRoute";

const NotFoundPage = React.lazy(() => import("pages/misc/NotFoundPage"));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

if (Boolean(import.meta.env.VITE_SENTRY_DSN)) {
  const options: Sentry.BrowserOptions = {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],
    tracesSampleRate: 0.25
  };

  if (import.meta.env.VITE_SENTRY_ENV) {
    options.environment = import.meta.env.VITE_SENTRY_ENV;
  }

  if (import.meta.env.VITE_SENTRY_RELEASE) {
    options.release = import.meta.env.VITE_SENTRY_RELEASE;
  }

  Sentry.init(options);
}

const Router: React.FC = () => {
  return (
    <SentryRoutes>
      <Route element={<PrivateRoute component={MainLayout} />}>
        <Route element={<Navigate to="/workouts" />} index />
        {privateRoutes.map((route, idx) => (
          <Route key={idx} {...route} />
        ))}
      </Route>
      {publicRoutes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
      <Route
        element={
          <React.Suspense fallback={<></>}>
            <NotFoundPage />
          </React.Suspense>
        }
        path="*"
      />
    </SentryRoutes>
  );
};

export { Router };
