import { NavItem } from "components/nav";

import NavSidebar from "../NavSidebar";

type DesktopNav = {
  items: NavItem[];
  onLogoutClick(): void;
};

export default function DesktopNavSidebar({
  onLogoutClick,
  items = []
}: DesktopNav) {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <NavSidebar items={items} onLogoutClick={onLogoutClick} />
    </div>
  );
}
