import { Button } from "@getwellen/valesco";
import { Badge } from "components/badge/Badge";
import { ContentLoader } from "components/content-loader/ContentLoader";
import PageHeader from "components/page-header/PageHeader";
import {
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableHeaderCol,
  TableRow
} from "components/table";
import { TablePagination } from "components/table/TablePagination";
import TableSearchForm from "components/table/TableSearchForm";
import { formatDistance } from "date-fns";
import { memo } from "react";
import { Link } from "react-router-dom";
import { getVideoConfigLabel } from "util/rendering";
import { secondsToTimeStr } from "util/time";

import useVideosListPage from "./useVideosListPage";

const VideosListPage = () => {
  const {
    videos,
    isLoading,
    totalCount,
    page,
    hasMore,
    handleSearch,
    handleNext,
    handlePrev
  } = useVideosListPage();

  if (isLoading) return <ContentLoader />;

  return (
    <>
      <PageHeader className="mb-8" title={`Videos (${totalCount})`} />
      <TableSearchForm
        className="mb-8"
        onSearch={handleSearch}
        placeholder="Find a video by its slug"
      />
      <Table>
        <TableHead>
          <TableHeaderCol>Type</TableHeaderCol>
          <TableHeaderCol>Duration</TableHeaderCol>
          <TableHeaderCol>Slug</TableHeaderCol>
          <TableHeaderCol>Parameters</TableHeaderCol>
          <TableHeaderCol>Last Updated</TableHeaderCol>
          <TableHeaderCol></TableHeaderCol>
        </TableHead>
        <TableBody>
          {videos.map((video, idx) => (
            <TableRow key={idx}>
              <TableCol>{video.render.videoType}</TableCol>
              <TableCol>{secondsToTimeStr(video.duration)}</TableCol>
              <TableCol>
                <Link
                  className="underline"
                  to={
                    video.render.videoType === "Exercise"
                      ? `/exercises/${video.render.config.slug}`
                      : `/transitions/${video.render.config.slug}`
                  }
                >
                  {video.render.config.slug}
                </Link>
              </TableCol>
              <TableCol className="text-clip">
                <Badge className="bg-cello-500 text-white">
                  {getVideoConfigLabel(video.render.config)}
                </Badge>
              </TableCol>
              <TableCol>
                <>
                  Last updated{" "}
                  {formatDistance(new Date(video.updatedAt), new Date(), {
                    addSuffix: true
                  })}
                </>
              </TableCol>
              <TableCol>
                <Link
                  className="text-cello-500 underline hover:text-cello-900"
                  to={`/videos/${video.id}`}
                >
                  <Button action="secondary" variant="subtle">
                    View
                    <span className="sr-only">
                      , {video.render.config.slug}
                    </span>
                  </Button>
                </Link>
              </TableCol>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        handleNext={handleNext}
        handlePrevious={handlePrev}
        page={page}
        pageSize={10}
        showNext={hasMore}
        showPrev={page > 1}
        total={totalCount}
      />
    </>
  );
};

export default memo(VideosListPage);
