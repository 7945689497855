import { useParams } from "react-router-dom";
import { trpc } from "util/trpc";

export default function useVideosPlaylistsTab() {
  const { id } = useParams();

  const { data, isLoading } =
    trpc.videoStreaming.playlists.getByVideoId.useQuery(
      {
        videoId: String(id)
      },
      { enabled: !!id }
    );

  return {
    playlists: data || [],
    isLoading
  };
}
