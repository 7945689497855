import React, { createContext, useContext } from "react";

const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID || "";
const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN || "";
const CMS_API_AUTH_TOKEN = import.meta.env.VITE_CMS_API_AUTH_TOKEN || "";
const CMS_API_URL = import.meta.env.VITE_CMS_API_URL || "http://localhost:1337";
const EXERCISE_PREVIEW_URL =
  import.meta.env.VITE_EXERCISE_PREVIEW_URL || undefined;
const FEED_FM_SECRET = import.meta.env.VITE_FEED_FM_SECRET || "";
const FEED_FM_TOKEN = import.meta.env.VITE_FEED_FM_TOKEN || "";
const RAILS_API_TOKEN = import.meta.env.VITE_RAILS_API_TOKEN || "";
const RAILS_API_URL = import.meta.env.VITE_RAILS_API_URL || "";
const VIDEO_API_URL = import.meta.env.VITE_VIDEO_API_URL || "";

interface EnvInterface {
  AUTH0_AUDIENCE: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_DOMAIN: string;
  CMS_API_AUTH_TOKEN: string;
  CMS_API_URL: string;
  EXERCISE_PREVIEW_URL?: string;
  FEED_FM_SECRET: string;
  FEED_FM_TOKEN: string;
  RAILS_API_TOKEN: string;
  RAILS_API_URL: string;
  VIDEO_API_URL: string;
}

const dotenv: EnvInterface = {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  CMS_API_AUTH_TOKEN,
  CMS_API_URL,
  EXERCISE_PREVIEW_URL,
  FEED_FM_SECRET,
  FEED_FM_TOKEN,
  RAILS_API_TOKEN,
  RAILS_API_URL,
  VIDEO_API_URL
};

const EnvContext = createContext<EnvInterface>(dotenv);

const useEnv = () => {
  const context = useContext(EnvContext);

  if (!context) {
    throw new Error(`useEnv must be used within a EnvProvider`);
  }

  return context;
};

const EnvProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <EnvContext.Provider value={dotenv}>{children}</EnvContext.Provider>;
};

export { EnvProvider, useEnv };
