import React, { createContext, useContext } from "react";

import { FeatureFlag, useGetFeatureFlagsQuery } from "../graphql/rails-api";
interface FeatureFlagInterface {
  features: FeatureFlag[];
  isLoading: boolean;
  isFeatureEnabled(feature: string): boolean;
}

const FeatureFlagContext = createContext<FeatureFlagInterface>({
  features: [],
  isLoading: false,
  isFeatureEnabled: () => false
});

const useFeatureFlag = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error("useFeatureFlag must be used within FeatureFlagProvider");
  }

  return context;
};

const FeatureFlagProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { data, loading } = useGetFeatureFlagsQuery({
    context: { clientName: "rails-api" }
  });

  const features = data?.getFeatureFlags || [];
  const isFeatureEnabled = (feature: string): boolean =>
    !!features.find((f) => f.feature === feature && f.enabled);

  return (
    <FeatureFlagContext.Provider
      value={{ features, isLoading: loading, isFeatureEnabled }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export { FeatureFlagProvider, useFeatureFlag };
