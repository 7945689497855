import { CalendarIcon, ClockIcon } from "@heroicons/react/24/outline";
import { Badge } from "components/badge/Badge";
import { ContentLoader } from "components/content-loader/ContentLoader";
import PageHeader from "components/page-header/PageHeader";
import { formatDistance } from "date-fns";
import { memo } from "react";
import { secondsToTimeStr } from "util/time";

import useStitchesViewPage from "./useStitchesViewPage";

const StichesViewPage = () => {
  const { stitch, isLoading } = useStitchesViewPage();

  if (isLoading) return <ContentLoader />;

  return (
    <div>
      <PageHeader
        breadcrumbs={[
          {
            href: "/stitches",
            label: "Stitches"
          },
          {
            href: `/stitches/${stitch.id}`,
            label: String(stitch.id)
          }
        ]}
        metaItems={[
          {
            icon: ClockIcon,
            label: secondsToTimeStr(stitch.duration)
          },
          {
            icon: CalendarIcon,
            label: (
              <>
                Last updated{" "}
                {formatDistance(new Date(stitch.updatedAt), new Date(), {
                  addSuffix: true
                })}
              </>
            )
          }
        ]}
        title={stitch.id}
      >
        <span className="sm:ml-3 sm:mb-2">
          {!stitch?.isComplete && (
            <Badge className="bg-red text-white p-2">Incomplete</Badge>
          )}
        </span>
      </PageHeader>
      <div className="py-4">
        <p className="mb-4 text-sm">Metadata</p>
        <pre>{JSON.stringify(stitch.metadata, null, 2)}</pre>
        <p className="mb-4 text-sm">Errors</p>
        {stitch.errors ? (
          <pre>{JSON.stringify(stitch.errors, null, 2)}</pre>
        ) : (
          <span>None</span>
        )}
      </div>
    </div>
  );
};

export default memo(StichesViewPage);
