import { Player } from "@getwellen/valesco";
import { ContentLoader } from "components/content-loader/ContentLoader";
import PageHeader from "components/page-header/PageHeader";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { bytesToMbps } from "util/video";

import useWatchPlaylistPage from "./useWatchPlaylistPage";

const WatchPlaylistPage = () => {
  const { id } = useParams();
  const { playlist, isLoading } = useWatchPlaylistPage();

  if (isLoading) return <ContentLoader />;

  return (
    <div>
      <PageHeader
        breadcrumbs={[
          {
            href: "/videos",
            label: "Videos"
          },
          {
            href: `/videos/${id}`,
            label: String(playlist.video.render.config.id)
          },
          {
            href: `/videos/${id}/playlists/${playlist.id}`,
            label: playlist.resolution
          }
        ]}
        subtitle={`${playlist.resolution} - ${bytesToMbps(
          playlist.bandwidth
        )} mbps`}
        title={`${playlist.video.render.videoType} - ${playlist.video.render.config.slug}`}
      />
      <Player controls={true} videoUrl={playlist.url} />
    </div>
  );
};

export default memo(WatchPlaylistPage);
