import StitchesViewPage from "pages/stitches/view/StitchesViewPage";
import React from "react";

import { RouteItem } from ".";

const StitchesListPage = React.lazy(
  () => import("pages/stitches/list/StitchesListPage")
);

const routes: RouteItem[] = [
  {
    path: "/stitches/:tab/list",
    element: (
      <React.Suspense fallback={<></>}>
        <StitchesListPage />
      </React.Suspense>
    )
  },
  {
    path: "/stitches",
    element: (
      <React.Suspense fallback={<></>}>
        <StitchesListPage />
      </React.Suspense>
    )
  },
  {
    path: "/stitches/:id",
    element: (
      <React.Suspense fallback={<></>}>
        <StitchesViewPage />
      </React.Suspense>
    )
  }
];

export { routes };
