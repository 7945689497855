import React from "react";

import { RouteItem } from ".";

const ProvidersListPage = React.lazy(
  () => import("pages/providers/list/ProvidersListPage")
);

const routes: RouteItem[] = [
  {
    path: "/providers",
    element: (
      <React.Suspense fallback={<></>}>
        <ProvidersListPage />
      </React.Suspense>
    )
  }
];

export { routes };
