import React from "react";

import { RouteItem } from ".";

const AuthenticationPage = React.lazy(
  () => import("pages/auth/AuthenticationPage")
);

const routes: RouteItem[] = [
  {
    path: "/authenticate",
    element: (
      <React.Suspense fallback={<></>}>
        <AuthenticationPage />
      </React.Suspense>
    )
  }
];

export { routes };
