import { Button, Select, Station, WorkoutPlayer } from "@getwellen/valesco";
import {
  getVideoStartTime,
  type VideoConfig
} from "@getwellen/video-streaming";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Badge } from "components/badge/Badge";
import { ContentLoader } from "components/content-loader/ContentLoader";
import PageHeader from "components/page-header/PageHeader";
import {
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableHeaderCol,
  TableRow
} from "components/table";
import { ChangeEvent, memo, useState } from "react";
import { Link } from "react-router-dom";
import { getVideoConfigLabel } from "util/rendering";
import { secondsToTimeStr } from "util/time";

import useWatchWorkoutPage from "./useWatchWorkoutPage";

const WatchWorkoutPage = () => {
  const {
    currentMix,
    currentStation,
    feedFm,
    isLoading,
    mixes,
    onJumpTo,
    onMixChange,
    onRegenerateStitch,
    onStationChange,
    segments,
    stitch,
    timeline,
    videoRef,
    videos,
    workout
  } = useWatchWorkoutPage();

  const [stations, setStations] = useState<string[]>([]);

  if (isLoading) return <ContentLoader />;

  return (
    <>
      <PageHeader
        breadcrumbs={[
          { label: "Workouts", href: "/workouts" },
          { label: String(workout.slug), href: `/workouts/${workout.slug}` },
          { label: "Watch", href: "#" }
        ]}
        title={`Watching ${workout.slug} - ${secondsToTimeStr(
          stitch?.duration || 0
        )}`}
      >
        <span className="sm:ml-3 sm:mb-2">
          {!stitch?.isComplete && (
            <Badge className="bg-red text-white p-2">Incomplete</Badge>
          )}
        </span>
        <span className="sm:ml-3 sm:mb-2">
          <Button
            action="secondary"
            onClick={onRegenerateStitch}
            variant="subtle"
          >
            <ArrowPathIcon className="size-5 text-cello-500 mr-1.5" />
            Refresh
          </Button>
        </span>
      </PageHeader>
      <WorkoutPlayer
        controls={true}
        feedFm={feedFm}
        onStationsLoaded={(stations: Station[]) => {
          setStations(stations.map((s) => s.name).concat(["None"]));
        }}
        showTopControls={true}
        stationName={currentStation}
        timeline={timeline || []}
        videoRef={videoRef}
        videoUrl={stitch?.mainPath ?? undefined}
      />
      <div className="my-4 grid grid-cols-2 gap-x-8">
        <Select
          className="flex-1"
          label="Select station"
          onChange={(e: ChangeEvent) => {
            const target = e.target as HTMLSelectElement;
            onStationChange(target.value);
          }}
          options={stations.map((station) => ({
            label: station,
            value: station
          }))}
          value={currentStation}
        />
        <Select
          className="flex-1"
          label="Select audio mix"
          onChange={(e: ChangeEvent) => {
            const target = e.target as HTMLSelectElement;
            onMixChange(target.value);
          }}
          options={mixes.map((mix) => ({
            label: mix.name,
            value: mix.name
          }))}
          value={currentMix.name}
        />
      </div>
      <div className="py-4">
        <Table>
          <TableHead>
            <TableHeaderCol>Slug</TableHeaderCol>
            <TableHeaderCol>Type</TableHeaderCol>
            <TableHeaderCol>Start</TableHeaderCol>
            <TableHeaderCol>Duration</TableHeaderCol>
            <TableHeaderCol>Video Config</TableHeaderCol>
            <TableHeaderCol></TableHeaderCol>
          </TableHead>
          <TableBody>
            {segments.map((segment, idx) => {
              const stitchVideo = videos.find(
                (v) => segment.slug == v.video.render.config.slug
              );
              const startTime = stitchVideo
                ? getVideoStartTime(stitchVideo, videos)
                : 0;

              return (
                <TableRow key={idx}>
                  <TableCol>
                    <Link
                      className="underline"
                      to={
                        segment.videoType === "Transition"
                          ? `/transitions/${segment.slug}`
                          : `/exercises/${segment.slug}`
                      }
                    >
                      {segment.slug}
                    </Link>
                  </TableCol>
                  <TableCol>{segment.videoType}</TableCol>
                  <TableCol>
                    {stitchVideo ? (
                      <button
                        className="underline"
                        onClick={() => onJumpTo(startTime)}
                      >
                        {secondsToTimeStr(startTime)}
                      </button>
                    ) : (
                      <>&#8212;</>
                    )}
                  </TableCol>
                  <TableCol>
                    {stitchVideo ? (
                      secondsToTimeStr(stitchVideo.video.duration)
                    ) : (
                      <>&#8212;</>
                    )}
                  </TableCol>
                  <TableCol>
                    {
                      <Badge className="bg-cello-500 text-white">
                        {getVideoConfigLabel(
                          stitchVideo
                            ? stitchVideo.video.render.config
                            : ({
                                slug: segment.slug,
                                videoType: segment.videoType,
                                parameters: segment.attributes
                              } as unknown as VideoConfig)
                        )}
                      </Badge>
                    }
                  </TableCol>
                  <TableCol>
                    {stitchVideo ? (
                      <Link to={`/videos/${stitchVideo.videoId}`}>
                        <Button action="secondary" variant="subtle">
                          View
                          <span className="sr-only">, {segment.slug}</span>
                        </Button>
                      </Link>
                    ) : (
                      <Badge className="bg-red text-white py-2">
                        Missing Video
                      </Badge>
                    )}
                  </TableCol>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default memo(WatchWorkoutPage);
