import React, { createContext, useContext, useState } from "react";

import { Modal } from "../components/modal/Modal";

const ModalContext = createContext<{
  setModal: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}>({
  setModal: () => null
});

const ModalProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const [modal, setModal] = useState<React.ReactNode>(null);

  return (
    <ModalContext.Provider value={{ setModal }}>
      {modal && <Modal>{modal}</Modal>}
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error("useModal must be used within a ModalContext.Provider");
  }

  return context;
};

export { ModalContext, ModalProvider, useModal };
