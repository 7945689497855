import { type StitchWithVideosRenderAndConfig } from "@getwellen/video-streaming";
import { useParams } from "react-router-dom";
import { trpc } from "util/trpc";

export default function useStitchesViewPage() {
  const { id } = useParams();

  const { data, isLoading } = trpc.videoStreaming.stitches.getById.useQuery({
    id: String(id)
  });

  return {
    stitch: data as StitchWithVideosRenderAndConfig,
    isLoading
  };
}
