import WatchWorkoutPage from "pages/workouts/watch/WatchWorkoutPage";
import React from "react";

import { RouteItem } from ".";

const WorkoutListPage = React.lazy(
  () => import("pages/workouts/list/WorkoutListPage")
);

const WorkoutViewPage = React.lazy(
  () => import("pages/workouts/view/WorkoutViewPage")
);

const WorkoutDesignerPage = React.lazy(
  () => import("pages/workouts/designer/WorkoutDesignerPage")
);

const routes: RouteItem[] = [
  {
    path: "/workouts",
    element: (
      <React.Suspense fallback={<></>}>
        <WorkoutListPage />
      </React.Suspense>
    )
  },
  {
    path: "/workouts/designer",
    element: (
      <React.Suspense fallback={<></>}>
        <WorkoutDesignerPage />
      </React.Suspense>
    )
  },
  {
    path: "/workouts/designer/:tab",
    element: (
      <React.Suspense fallback={<></>}>
        <WorkoutDesignerPage />
      </React.Suspense>
    )
  },
  {
    path: "/workouts/:slug/watch",
    element: (
      <React.Suspense fallback={<></>}>
        <WatchWorkoutPage />
      </React.Suspense>
    )
  },
  {
    path: "/workouts/:slug/:tab",
    element: (
      <React.Suspense fallback={<></>}>
        <WorkoutViewPage />
      </React.Suspense>
    )
  },
  {
    path: "/workouts/:slug",
    element: (
      <React.Suspense fallback={<></>}>
        <WorkoutViewPage />
      </React.Suspense>
    )
  }
];

export { routes };
