import { Button, TextInput } from "@getwellen/valesco";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { SyntheticEvent, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type TableSearchForm = {
  className?: string;
  onSearch(term: string): void;
  placeholder: string;
  initialTerm?: string;
};

export default function TableSearchForm({
  className = "",
  onSearch,
  placeholder
}: TableSearchForm) {
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams.get("search") || ""
  );

  const handleSearch = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();

      if (searchTerm.length > 0) {
        onSearch(searchTerm);
      }
    },
    [searchTerm]
  );

  return (
    <form className={twMerge("mt-1 flex rounded-md w-full", className)}>
      <div className="relative flex grow items-stretch focus-within:z-10">
        <TextInput
          className="mr-2"
          name="search-term"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(String(e.target.value))
          }
          placeholder={placeholder}
          value={searchTerm}
        />
        <button
          className="absolute top-1/3 right-6 size-5"
          onClick={handleSearch}
        >
          <MagnifyingGlassIcon aria-hidden="true" className="text-cello-500" />
        </button>
      </div>
      <Button action="secondary" className="h-full" onClick={handleSearch}>
        Go
      </Button>
    </form>
  );
}
