import React from "react";

import { RouteItem } from ".";

const RendersListPage = React.lazy(
  () => import("pages/renders/list/RendersListPage")
);

const RendersViewPage = React.lazy(
  () => import("pages/renders/view/RendersViewPage")
);

const routes: RouteItem[] = [
  {
    path: "/renders/:tab/list",
    element: (
      <React.Suspense fallback={<></>}>
        <RendersListPage />
      </React.Suspense>
    )
  },
  {
    path: "/renders",
    element: (
      <React.Suspense fallback={<></>}>
        <RendersListPage />
      </React.Suspense>
    )
  },
  {
    path: "/renders/:id",
    element: (
      <React.Suspense fallback={<></>}>
        <RendersViewPage />
      </React.Suspense>
    )
  }
];

export { routes };
