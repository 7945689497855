import { notify, WorkoutMix } from "@getwellen/valesco";
import {
  buildStream,
  buildStreamTimeline,
  type StitchWithVideosRenderAndConfig
} from "@getwellen/video-streaming";
import { useEnv } from "contexts";
import {
  PublicationState,
  useWorkoutsQuery,
  Workout,
  WorkoutEntity
} from "graphql/strapi-cms";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import { trpc } from "util/trpc";

export default function useWatchWorkoutPage() {
  const { slug } = useParams();
  const { FEED_FM_SECRET, FEED_FM_TOKEN } = useEnv();
  const feedFm = {
    token: FEED_FM_TOKEN,
    secret: FEED_FM_SECRET
  };
  const [currentStation, setCurrentStation] = useState<string>("Pop");
  const [currentMix, setCurrentMix] = useState<WorkoutMix>({
    voice: 0.75,
    music: 0.75,
    name: "Balanced"
  });
  const mixes = [
    { voice: 0.75, music: 0.75, name: "Balanced" },
    { voice: 0.5, music: 1, name: "Music" },
    { voice: 1, music: 0.5, name: "Voice" }
  ];

  const { data, loading: isLoading } = useWorkoutsQuery({
    variables: {
      publicationState: PublicationState.Preview,
      slug: String(slug)
    },
    skip: !slug
  });

  const workoutData = data?.workouts?.data?.[0] as WorkoutEntity;
  const workout = workoutData?.attributes as Workout;
  const videoRef = useRef<ReactPlayer>(null);
  const segments = buildStream(workout, 10);
  const archiveStitch = trpc.videoStreaming.stitches.archive.useMutation();
  const {
    data: stitchData,
    isLoading: stitchIsLoading,
    refetch: refetchStitch,
    error
  } = trpc.videoStreaming.stitches.findOrCreate.useQuery(
    {
      segments,
      metadata: {
        type: "workout",
        slug: workoutData?.attributes?.slug
      }
    },
    { enabled: segments.length > 0, retry: 2 }
  );

  const onJumpTo = (seconds: number) => {
    if (videoRef.current) {
      videoRef.current.seekTo(seconds);
    }
  };

  const onStationChange = (station: string) => {
    setCurrentStation(station);
  };

  const onMixChange = (mixName: string) => {
    const newMix = mixes.find(
      (mix) => mix.name.toLowerCase() === mixName.toLowerCase()
    );

    if (newMix) setCurrentMix(newMix);
  };

  const onRegenerateStitch = async () => {
    if (stitchData) {
      await archiveStitch.mutateAsync({
        id: stitchData.id
      });

      void refetchStitch();
    }
  };

  useEffect(() => {
    if (error)
      notify.error(error.message || "Something went wrong building the video.");
  }, [error]);

  const videos =
    (stitchData as StitchWithVideosRenderAndConfig)?.stitchVideos || [];

  const timeline = buildStreamTimeline(videos);

  return {
    workout,
    stitch: stitchData,
    isLoading: isLoading || stitchIsLoading,
    workoutId: workoutData?.id,
    videos,
    timeline,
    videoRef,
    onJumpTo,
    onStationChange,
    onRegenerateStitch,
    currentStation,
    mixes,
    onMixChange,
    currentMix,
    segments,
    feedFm
  };
}
