import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export interface Tab {
  name: string;
  href: string;
  current: boolean;
}

const Tabs: React.FC<{
  className?: string;
  tabs: Tab[];
}> = ({ tabs, className = "" }) => (
  <div className={className}>
    <div className="sm:hidden">
      <label className="sr-only" htmlFor="tabs">
        Select a tab
      </label>
      {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
      <select
        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-lynch-500 focus:outline-none focus:ring-lynch-500 sm:text-sm"
        defaultValue={tabs.find((tab) => tab.current)?.name}
        id="tabs"
        name="tabs"
      >
        {tabs.map((tab) => (
          <option key={tab.name}>{tab.name}</option>
        ))}
      </select>
    </div>
    <div className="hidden sm:block">
      <div className="border-b border-gray-200">
        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              aria-current={tab.current ? "page" : undefined}
              className={twMerge(
                tab.current
                  ? "border-lynch-500 text-lynch-600"
                  : "border-transparent text-cello-500 hover:text-cello-700 hover:border-gray-300",
                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              )}
              to={tab.href}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  </div>
);

export { Tabs };
